import { all, takeLatest, put, call } from "redux-saga/effects"
import { GET_TRADING_CARD, } from "./actionTypes"
import {
  getTradingCardSuccess,
  getTradingCardFail,

  getTradingCard,


} from "./action"
import XHR from "../../../utils/XHR"
import { BASE_URL } from "../../../config/app"
import { toast } from "react-hot-toast"
import { toast as toastify } from "react-toastify"

async function getTradingCardApi(data) {
  const datapass = data ? `${data}` : ""
  const URL = `${BASE_URL}/api/v1/admin/get_all_trading_cards/${datapass}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* onPostLoginData({ data }) {
  try {
    const response = yield call(getTradingCardApi, data)
    yield put(getTradingCardSuccess(response.data))
  } catch (error) {
    yield put(getTradingCardFail(error.response))
  }
}



export default all([
  takeLatest(GET_TRADING_CARD, onPostLoginData),

])
