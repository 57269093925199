import { GET_TRADING_CARD, GET_TRADING_CARD_SUCCESS, GET_TRADING_CARD_FAIL } from "./actionTypes"

export const getTradingCard = data => ({
  type: GET_TRADING_CARD,
  data
})

export const getTradingCardSuccess = data => {
  return {
    type: GET_TRADING_CARD_SUCCESS,
    data
  }
}

export const getTradingCardFail = error => ({
  type: GET_TRADING_CARD_FAIL,
  error
})

