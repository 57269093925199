import { combineReducers } from "redux"
import LoginReducer from "./pages/login/redux/reducers"
import user from "./pages/Management/redux/reducers"
import ContentManagement from "./pages/ContentManagement/redux/reducer"
import SalesManagement from "./pages/SalesManagement/redux/reducers"
import Dashboard from './pages/Dashboard/redux/reducers'
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import transactions from "./pages/PayoutManagment/redux/reducer"
import helpCenter from "./pages/HelpCenter/redux/reducers"
import TradingCard from "./pages/TradingCard/redux/reducers"

const presistConfig = {
  key: "LoginReducer",
  storage
}

export const combinedReducers = combineReducers({
  LoginReducer: persistReducer(presistConfig, LoginReducer),
  ContentManagement,
  user,
  SalesManagement,
  Dashboard,
  transactions,
  helpCenter,
  TradingCard
})

