// @ts-nocheck
import React from "react"
import Login from "./pages/login"
import Dashboard from "./pages/Dashboard"
import Managment from "./pages/Management"
import ContentManagement from "./pages/ContentManagement"
import Anayltics from "./pages/Anayltics"
import Messages from "./pages/Messages"
import Mail from "./pages/Mail"
import HelpCenter from "./pages/HelpCenter"
import Profile from "./pages/Profile"
import Settings from "./pages/Settings"
import "react-toastify/dist/ReactToastify.css"
import './assets/fonts/fonts.scss'
import {
  Navigate,
  Outlet,
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { ToastContainer } from "react-toastify"

import { useSelector } from "react-redux"
import PayoutManagment from "./pages/PayoutManagment"
import SalesManagement from "./pages/SalesManagement"
import ReOauth from "./pages/ReOauth"
import Return from "./pages/Return"
import FbConnect from "./pages/FbConnect"
import InstaConnect from "./pages/InstaConnect"
import SupportMessages from "./pages/SupportMessages"
import TradingCard from "./pages/TradingCard"

function App() {
  const RenderRoutes = () => {
    const { accessToken } = useSelector(state => state.LoginReducer)
    if (!accessToken) {
      return <Navigate to="/login" />
    }
    return <Outlet />
  }

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <ToastContainer />
      <Router>
        <Routes>
          <Route element={<RenderRoutes />}>
            <Route index path="/" element={<Dashboard />} />
            <Route index path="/user-management" element={<Managment />} />
            <Route index path="/payout-management" element={<PayoutManagment />} />
            <Route index path="/sales-management" element={<SalesManagement />} />
            <Route index path="/analytics" element={<Anayltics />} />
            <Route index path="/messages" element={<Messages />} />
            <Route index path="/mail" element={<Mail />} />
            <Route index path="/help-center" element={<HelpCenter />} />
            <Route index path="/message" element={<SupportMessages />} />
            <Route index path="/profile" element={<Profile />} />
            <Route index path="/settings" element={<Settings />} />
            <Route
              index
              path="/content-management"
              element={<ContentManagement />}
            />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/re-oauth" element={<ReOauth />} />
          <Route path="/return" element={< Return />} />
          <Route path="/fb-connect" element={< FbConnect />} />
          <Route path="/insta-connect" element={< InstaConnect />} />
          <Route
            index
            path="/trading-card"
            element={<TradingCard />}
          />
          <Route
            path="*"
            element={
              <h1
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                Page Not Found
              </h1>
            }
          />
        </Routes>
      </Router>
    </>
  )
}

export default App
