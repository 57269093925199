import { GET_TRADING_CARD, GET_TRADING_CARD_SUCCESS, GET_TRADING_CARD_FAIL,  } from "./actionTypes"

const initialState = {
  data: false,
  loader: false,


}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TRADING_CARD:
      return {
        ...state,
        loader: true
      }
    case GET_TRADING_CARD_SUCCESS:
      return {
        ...state,
        data: action.data,
        loader: false
      }
    case GET_TRADING_CARD_FAIL:
      return {
        ...state,
        error: action.error,
        loader: false
      }
  
     
    default:
      return state
  }
}
