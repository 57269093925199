// @ts-nocheck
import React from "react"
import HomeLayout from "../../layout/HomeLayout"
import PubNub from "pubnub"
import { PubNubProvider } from "pubnub-react"
import SupportChat from "./Chat"

const SupportMessages = () => {
  const pubnub = new PubNub({
    publishKey: "pub-c-3a44ac7a-f9c7-4752-a442-a9f6e7c3f93d",
    subscribeKey: "sub-c-ae8c153d-4bdc-43a0-a72b-4aed6a463fd5",
    userId: "admin_01"
  })

  return (
    <>
      <HomeLayout heading={'Support Message'}>
        <PubNubProvider client={pubnub}>
          <SupportChat />
        </PubNubProvider>
      </HomeLayout>
    </>
  )
}
export default SupportMessages
