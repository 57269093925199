// @ts-nocheck
import React, { useState, useEffect } from "react"
import { Chat, MessageList, MemberList } from "@pubnub/react-chat-components"
import { usePubNub } from "pubnub-react"
import style from "./style.module.scss"
import { Input } from "antd"
import { fetchChannels, sendMessage, generateUUID } from "../../utils/utils"

const SupportChat = () => {
  const pubnub = usePubNub()
  const [channelMembers, setChannelMembers] = useState([])
  const [channelCount, setChannelCount] = useState([])
  const [selectMember, setSelectMember] = useState({})
  const [inputValue, setInputValue] = useState("")
  const [disabled, setDisabled] = useState(false)

  const theme = "dark"

  const user = {
    name: "Admin",
    _id: "admin_01"
  }

  const pubnubListener = {
    message: () => {
      unreadMessages()
    },
  }


  const getActiveChannels = async () =>{
    let channelList = []
    await fetchChannels(pubnub, user?._id).then(res => {
      const channels = Object.entries(res)
        .map(([id, rest]) => ({
          id,
          ...rest
        }))
        .filter(item => {
          return item.name
        })
        .map(obj => {
          obj?.id && pubnub.subscribe({ channels: [obj?.id] })
          return { ...obj }
        })
        channelList = channels
    })
    return channelList
  }


  const leaveApplication = () => {
    console.log('leaveApplication')
    pubnub.removeListener(pubnubListener);
    pubnub.unsubscribeAll()
  }


  useEffect(async () => {
   await fetchChannels(pubnub, user?._id).then(res => {
      const channels = Object.entries(res)
        .map(([id, rest]) => ({
          id,
          ...rest
        }))
        .filter(item => {
          return item.name
        })
        .map(obj => {
          obj?.id && pubnub.subscribe({ channels: [obj?.id] })
          return { ...obj }
        })
      setChannelMembers(channels)
    })

  await unreadMessages()

  return leaveApplication
    
  }, [])

  useEffect(() => {
    pubnub.addListener(pubnubListener)
  }, [])


  const unreadMessages = async () => {
    const channelList = await getActiveChannels()
    const selectChannel = []
    const selectTimetokens = []
    let countData = []
   await pubnub.objects
      .getMemberships({
        include: {
          customFields: true
        }
      })
      .then(res => {
        res?.data?.map(item => {
          let isChannelExist = channelList?.find(e => e.id == item?.channel?.id )
          if (item?.channel?.id && item?.custom?.lastReadTimetoken && isChannelExist) {
            selectChannel.push(item?.channel?.id)
            selectTimetokens.push(item?.custom?.lastReadTimetoken)
          }
        })
      })

      await pubnub.messageCounts({
                channels: [...selectChannel],
                channelTimetokens: [...selectTimetokens]
              })
              .then(resMsg => {
                Object.entries(resMsg?.channels)
                  .map(([id, rest]) => ({
                    id,
                    rest
                  }))
                  .map(obj => {
                    countData.push({ id: obj?.id, count: obj?.rest })
                  })
              })
      setChannelCount(countData)
  }

  const historyMessage = async (channelId) => {
    if (channelId) {
      await pubnub.history({ channel: channelId }).then(async res => {
        if (res?.endTimeToken) {
          try {
           await pubnub.objects
            .setMemberships({
              channels: [
                {
                  id: channelId,
                  custom: {
                    lastReadTimetoken: res?.endTimeToken
                  }
                }
              ]
            })
          } catch (error) {
            console.log(`Error on update setMemberships ${error}`);
          }
         
        }
      })

      let selectedChannel = [...channelCount]
      var foundIndex = selectedChannel.findIndex(x => x.id == channelId);
      selectedChannel[foundIndex].count = 0;
      setChannelCount(selectedChannel)
     
    }
  }

  const onMemberClicked = async item => {
    setSelectMember(item)
    await historyMessage(item?.id)
  }

  const onKeyDown = e => {
    if (e.key === "Enter") {
      onSend()
    }
  }

  const onSend = () => {
    if (inputValue && !disabled) {
      const newMessage = [
        {
          createdAt: +new Date(),
          _id: `${generateUUID()}`,
          text: inputValue,
          type: "text",
          user: user
        }
      ]
      setDisabled(true)
      sendMessage(pubnub, selectMember?.id, newMessage[0]).then(
        ({ status, response }) => {
          if (status.statusCode === 200) {
            setInputValue("")
            setDisabled(false)
            pubnub.objects
              .setMemberships({
                channels: [
                  {
                    id: selectMember?.id,
                    custom: {
                      lastReadTimetoken: response?.timetoken
                    }
                  }
                ]
              })
              .then(res => {
                console.log("setMemberships", res)
              })
          }
        }
      )
    }
  }

  const messageRenderer = messageList => {
    return (
      <div className={style.messageWraper}>
        {messageList?.isOwn === true ? (
          <div className={style.messageBox}>
            {messageList?.message?.message?._id && (
              <p className={style.messageText}>
                {messageList?.message?.message?.text}{" "}
              </p>
            )}
            {messageList?.message?.message?._id && (
              <p className={style.messageTime}>{messageList?.time} </p>
            )}
          </div>
        ) : null}
        {messageList?.isOwn === false ? (
          <div className={style.messageBox}>
            {messageList?.message?.message?._id && (
              <p className={style.messageText}>
                {messageList?.message?.message?.text}{" "}
              </p>
            )}
            {messageList?.message?.message?._id && (
              <p className={style.messageTime}>{messageList?.time} </p>
            )}
          </div>
        ) : null}
      </div>
    )
  }

  const firstLetterCap = value => {
    if (value?.length > 1) {
      return `${value[0]?.slice(0, 1)?.toUpperCase()}${value[1]
        ?.slice(0, 1)
        ?.toUpperCase()}`
    } else {
      return value[0]?.slice(0, 2)?.toUpperCase()
    }
  }

  const MemberRender = member => {
    return (
      <div
        className={
          member == selectMember
            ? `${style.membersStyle} ${style.selectedMemberStyle}`
            : style.membersStyle
        }
        onClick={() => onMemberClicked(member)}
      >
        {/* <img className={style.profilePicStyle} src="" alt="user profile" /> */}
        <div className={style.profileStyle}>
          {`${firstLetterCap(member?.name?.split(" "))}`}
        </div>
        <h1 className={style.nameStyle}>{member?.name}</h1>
        {channelCount?.map(item => {
          if (item?.id === member?.id && item?.count > 0 && member?.id !== selectMember?.id) {
            return <p className={style.unreadCount}>{item?.count}</p>
          } else if (item?.id === member?.id && item?.count > 0 && member?.id === selectMember?.id) {
            historyMessage(selectMember?.id)
          }
        })}
      </div>
    )
  }

  return (
    <div className={style.chatMainWrapper}>
      <Chat users={[user]} currentChannel={selectMember?.id} theme={theme}>
        <div className={style.membersList}>
          <h3>Members</h3>
          <MemberList members={channelMembers} memberRenderer={MemberRender} />
        </div>
        {selectMember?.id && (
          <>
            <div className={style.messageListWrapper}>
              {/* <h3>{selectMember.name}</h3> */}
              <div className={style.messageList}>
                <MessageList
                  messageRenderer={messageRenderer}
                  fetchMessages={100}
                />
              </div>
              <div className={style.messageInput}>
                {/* <MessageInput onSend={onSend} /> */}
                <Input
                  onKeyDown={onKeyDown}
                  placeholder="Send message"
                  enterButton={true}
                  value={inputValue}
                  onChange={val => setInputValue(val.target.value)}
                />
                <button
                  aria-label="Send"
                  class="pn-msg-input__send false"
                  title="Send"
                  disabled={disabled}
                  onClick={onSend}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    overflow="visible"
                    preserveAspectRatio="none"
                    viewBox="0 0 24 24"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="currentColor"
                      vector-effect="non-scaling-stroke"
                      d="m2 21 21-9L2 3v7l15 2-15 2z"
                    ></path>
                  </svg>
                </button>
              </div>
            </div>
          </>
        )}
      </Chat>
    </div>
  )
}

export default SupportChat
