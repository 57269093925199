// @ts-nocheck
import React, { useEffect, useState } from "react"
import HomeLayout from "../../layout/HomeLayout"
import { SearchOutlined, PlusOutlined } from "@ant-design/icons"
import { Input, Table, Tooltip, Divider, Select, Row, Col } from "antd"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import style from "./style.module.scss"

import ManagementTable from "../../Components/ManagementTable"
import warning from "../../assets/Images/warning.png"
import edit from "../../assets/Images/edit.svg"
import trash from "../../assets/Images/trash.svg"
import { connect, useDispatch, useSelector } from "react-redux"
import { deleteUser, editUser, getUser } from "./redux/action"
import CommonButton from "../../common/button"
import CreateUserDrawer from "./CreactUserDrawer"
import AntdModal from "../../Components/AntdModal"
import arrowIcon from "../../assets/Images/Arrow-Down.svg"
import { getUniversity } from "../ContentManagement/redux/action"

const Management = props => {
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [deleteUserId, setDeleteUserId] = useState("")
  const [search, SetSearch] = useState()
  const [userUpdateDrawer, setUserUpdateDrawer] = useState({
    isUpdate: false,
    isUpdateData: {}
  })
  const [UniveristyData, setUniversityData] = useState([])
  const [selectUniversty, setSelectUniversty] = useState(null)

  const openDrawer = () => {
    setOpen(true)
  }
  const onUpdateDrawer = value => {
    setOpen(true)
    setUserUpdateDrawer({
      isUpdate: true,
      isUpdateData: value
    })
  }
  const onClose = () => {
    setOpen(false)
    setUserUpdateDrawer({
      isUpdate: false,
      isUpdateData: {}
    })
  }

  const {
    getUser,
    userData,
    requesting,
    updateUserloader,
    deleteUserloader,
    editUser
  } = props

  const { university } = useSelector(state => state.ContentManagement)
  useEffect(() => {
    dispatch(getUniversity())
  }, [])
  useEffect(() => {
    if (university) {
      const university_array = []
      university?.map((a, b) => {
        university_array.push({ value: a.id, label: a.name })
      })
      setUniversityData(university_array)
    }
  }, [university])
  const handleChangeUniversty = (value) => {
    if (value) {
      setSelectUniversty(value)
      dispatch(getUser({ selectUniversty: value }))
    }
    else {
      setSelectUniversty(null)
      dispatch(getUser())
    }
  }

  const handleDelete = () => {
    const data = {
      id: deleteUserId,
      setDeleteModal: setDeleteModal
    }
    props.deleteUser(data)
    setDeleteUserId("")
  }

  const handleCancelDelete = e => {
    setDeleteModal(false)
  }
  const handleSearch = (e) => {
    var { value } = e.target;
    SetSearch(value)
    getUser(`?search=${value}`)
  }

  useEffect(() => {
    if (getUser) {
      getUser("")
    }
  }, [getUser])

  const columns = [
    {
      title: "No.",
      dataIndex: "No",
      minWidth: "60px",
      width: "60px",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "ID",
      dataIndex: "ID",
      width: "60px",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "User Name",
      dataIndex: "UserName",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "Email ID",
      dataIndex: "EmailID",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "University",
      dataIndex: "University",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "Sports",
      dataIndex: "Sports",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: text => {
        return (
          <a style={{ display: "flex", alignItems: "center" }}>
            {text === "Active" ? (
              <>
                <div
                  style={{
                    borderRadius: 20,
                    marginRight: 10,
                    width: 10,
                    height: 10,
                    background: "#59B4D1"
                  }}
                ></div>
                <Tooltip title={text}>{text}</Tooltip>
              </>
            ) : (
              <>
                <div
                  style={{
                    borderRadius: 20,
                    marginRight: 10,
                    width: 10,
                    height: 10,
                    background: "red"
                  }}
                ></div>
                <span style={{ color: "#55585A" }}>
                  <Tooltip title={text}>{text}</Tooltip>
                </span>
              </>
            )}
          </a>
        )
      }
    },
    {
      title: "Created On",
      dataIndex: "CreatedOn",
      render: text => {
        return <Tooltip title={text}>{text}</Tooltip>
      }
    },
    {
      title: "Actions",
      render: (text, row) => {
        return (
          <div>
            <Tooltip title="Edit User">
              <img
                onClick={() => {
                  onUpdateDrawer(row)
                }}
                src={edit}
                width={16}
                style={{ color: "#55585A", cursor: "pointer" }}
              />
            </Tooltip>
            <img width={16} style={{ color: "#55585A" }} />
            <img width={16} style={{ color: "#55585A" }} />
            <Tooltip title="Delete User">
              <img
                onClick={() => {
                  setDeleteModal(true)
                  setDeleteUserId(row?.ID)
                }}
                src={trash}
                width={16}
                style={{ color: "#55585A", marginLeft: 20, cursor: "pointer" }}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ]
  return (
    <HomeLayout heading="User Management" subHeading="All Users">
      <section
        className={style.managementContainer}
        style={{ overflow: "hidden !important" }}
      >
        <CreateUserDrawer
          onClose={onClose}
          open={open}
          userUpdateDrawer={userUpdateDrawer}
        />
        <div className={style.antdModalMain}>
          {deleteModal && (
            <AntdModal
              modalTitle="Delete"
              isShow={deleteModal}
              onOk={handleDelete}
              onCancel={handleCancelDelete}
              confirmLoading={deleteUserloader}
              okText="Delete"
              cancelText="Cancel"
            >
              <div className={style.deletModalContent}>
                <img src={warning} height={97} width={96} />
                <p style={{ color: "#898F96" }}>
                  Are you sure you want to delete this user?
                </p>
              </div>
            </AntdModal>
          )}
        </div>
        <Row className={style.mainHeader} gutter={20}>
          <Col xl={8} lg={12} md={24} span={24} className={style.filtersContainer}>
            <Select
              showSearch
              className={style.selectorStyle}
              suffixIcon={<img src={arrowIcon} />}
              placeholder="Filter by University"
              popupClassName={style.dropdownstyle}
              onChange={handleChangeUniversty}
              filterOption={false}
              allowClear
              options={UniveristyData}
              showSearch
              onSearch={val => dispatch(userData(val))}
            />
          </Col>
          <Col xl={8} lg={12} md={24} span={24} className={style.addbuttonMain}>
            <Input
              className={style.searchContainer}
              prefix={<SearchOutlined style={{ color: "#55585A" }} />}
              onChange={handleSearch}
              value={search}
            />
            <div>
              <CommonButton
                title="Add User"
                onBtnClick={openDrawer}
                icon={<PlusOutlined />}
                type="dark"
                customClass={style.addBtn}
              />
            </div>
          </Col>
        </Row>
        <div style={{ marginBottom: "65px" }}>
          <Divider />
          {requesting ? (
            <Table
              rowKey="key"
              className={style.teamemberTableSkelton}
              pagination={false}
              dataSource={[...Array(5)].map((_, index) => ({
                key: `key${index}`
              }))}
              columns={columns?.map(column => {
                return {
                  ...column,
                  render: function renderPlaceholder() {
                    return (
                      <>
                        <SkeletonTheme
                          baseColor="#1f2732"
                          highlightColor="#5c6f87"
                        >
                          <Skeleton width="70%" height={10} />
                        </SkeletonTheme>
                      </>
                    )
                  }
                }
              })}
            />
          ) : (
            <ManagementTable columns={columns} tableData={userData} />
          )}
        </div>
      </section>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  userData: state.user.data,
  requesting: state.user.loader,
  updateUserloader: state.user.updateUserloader,
  deleteUserloader: state.user.deleteUserloader
})

const mapDispatchToProps = dispatch => ({
  getUser: data => dispatch(getUser(data)),
  deleteUser: data => dispatch(deleteUser(data)),
  editUser: data => dispatch(editUser(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Management)
